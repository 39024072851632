import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import EditMember from 'components/users/members/EditMember';
import { t } from 'src/i18n/config';

const MembersPage = (props) => {
  const { location = {} } = props;
  const { state = { member: {} } } = location;

  return (
    <Layout>
      <Helmet>
        <title>{t('familyMembersSetup')}</title>
      </Helmet>
      <EditMember member={state.member}></EditMember>
    </Layout>
  );
};

export default MembersPage;
